/*
 * Rules affecting all landscape oriented devices
 */
@media only screen and (orientation: landscape) {
  .landscape-header ion-toolbar {
    height: 70px;
  }

  .cookie-dialog {
    justify-content: end;
    align-items: end;

    .alert-wrapper {
      margin: 20px;
    }
  }

  /*
   * Rules targeting smartphones/phablets in landscape mode
   */
  @media (max-width: 1023px) {
    ion-nav > .ion-page > ion-content {
      > .fixed-content, > .scroll-content {
        margin-top: 56px !important;
      }
    }

    .landscape-header {
      display: none;
    }

    .desktop-only {
      display: none !important;
    }
  }

  /*
   * Rules targeting desktop/tablets in landscape mode
   */
  @media (min-width: 1024px) {
    .alert-wrapper {
      max-width: 600px !important;
    }

    .lang-switcher {
      font-size: 30px;
      line-height: 0;
      margin: 0 17px;

      button {
        margin: 0;
        padding: 0;
        background-position: center;
        background-size: cover;
        height: 20px;
        width: 33px;
        opacity: 0.5;

        &.lang-de {
          background-image: url('../assets/images/lang/de.svg');
        }

        &.lang-nl {
          background-image: url('../assets/images/lang/nl.svg');
        }

        &.lang-en {
          background-image: url('../assets/images/lang/en.svg');
        }

        &.selected {
          opacity: 1;
        }
      }
    }

    @media (min-width: 1024px) {
      .wide::part(content) {
        min-width: 250px;
        width: auto;
      }
    }

    ion-app:not(.platform-mobile) {
      .user-menu-popover::part(content) {
        //margin-top: 70px;
      }
    }

    ion-router-outlet[id="main"] {
      box-shadow: 1px 0 3px rgba(0, 0, 0, 0.4);
      top: 140px;
      z-index: 1;

      &:not(.slim) > .ion-page > ion-content {
        > .fixed-content, > .scroll-content {
          margin-top: 0 !important;
        }
      }

      &.slim-header {
        top: 70px;
      }
    }

    ion-header.mobile-only + ion-content {
      .fixed-content, .scroll-content {
        margin-top: 0 !important;
      }
    }

    .app-root {
      display: flex;
      flex-flow: column nowrap;
    }

    .bg-attribution {
      display: block;
    }

    .desktop-secondary {
      background-color: var(--ion-color-secondary)
    }

    .landscape-header {
      display: block;
      position: relative;
      background: var(--ion-color-primary);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
      width: 100%;
      z-index: 2;

      .content-wrapper {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: flex-end;
        height: 140px;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;

        .toolbar {
          padding: 0;
          .toolbar-content {
            width: 1230px;
            padding: 0 21.6px;
            flex: none;
            display: flex;
          }
        }

        .button-toolbar {
          height: 70px;
          font-size: 24px;
          text-transform: none;
          font-weight: 500;
          letter-spacing: 0;
          border-radius: 0;
          border-bottom: 3px solid transparent;
          --padding-start: 15px;
          --padding-end: 15px;
          margin: 0 10px;

          .icon {
            font-size: 1em;
            line-height: 1.5em;
          }

          &.button-has-icon-only {
            font-size: 15px;
            //--padding-start: 10px;
            //--padding-end: 10px;
            margin: 0;
          }

          &.active {
            border-bottom: 3px solid #fff;
          }

          &:first-child {
            margin: 0 15px 0 0;
          }
        }
      }

      .app-logo {
        position: absolute;
        display: block;
        height: 140px;
        width: 140px;

        &.slim {
          display: none;
        }
      }

      &.slim-header .content-wrapper {
        height: initial;
      }
    }

    // duplicates the map.component.scss
    // #map {
    //   top: 140px !important;
    //   // left: 400px !important;
    // }

    .menu-content.slim {
      width: 400px;
    }

    .mobile-only {
      display: none !important;
    }

    @media (max-width: 1279px) {
      .landscape-header .content-wrapper {
        max-width: 976px;

        .toolbar {
          .toolbar-content {
            width: 976px;
            padding: 0 10px;
          }
        }

        .button-toolbar {
          margin: 1px;
        }
      }

      // duplicates the map.component.scss ?
      // #map {
        // left: 360px !important;
      // }

      .menu-content.slim {
        width: 360px;
      }
    }

    @media (max-width: 1530px) {
      .landscape-header .app-logo {
        display: none;
      }
    }

    @media (min-width: 1531px) {
      .landscape-header .content-wrapper {
        height: 140px; // 2.5x normal size
      }
    }

    @media (min-width: 2048px) {
      .landscape-header .content-wrapper {
        max-width: 1600px;
      }

      .toolbar {
        .toolbar-content {
          width: 1600px;
          padding: 0 21.6px;
        }
      }
    }
  }
}
