/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "scss/button";
@import "scss/fab";
@import "scss/flex";
@import "scss/fonts";
@import "scss/icons";
@import "scss/list";
@import "scss/maplibre-gl";
@import "scss/map";
@import "scss/noscript";
@import "scss/page";
@import "scss/parallax";
@import "scss/popover";
@import "scss/tab";
@import "scss/tooltips";
@import "scss/weather-icons";

@import 'swiper/swiper-bundle.min.css';
@import '@ionic/angular/css/ionic-swiper';

.alert-button-role-consent {
  color: var(--ion-color-secondary) !important;
}

.alert-button-role-deny {
  color: #888 !important;
}

.gu-mirror .select-md{
  padding:5px;
}

.clickable {
  cursor: pointer;
}

ion-app {
  --ion-font-family: "Roboto", "Helvetica Neue", sans-serif;
}

ion-button {
  letter-spacing: normal;
}

p, ol, ul, .text {
  line-height: 1.5em;
}

b, strong, h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

tr {
  padding: 5px 0;
}
tr.today {
  //border-bottom: 1px solid var(--ion-color-primary);
  //background-color: #faf2cc;
  font-weight: 500;
}
td {
  padding: 5px 10px;

  &:first-child {
    padding: 5px 10px 5px 0;
  }

  &:last-child {
    padding: 5px 0 5px 10px;
  }
}
td.workday {
  //background: mix(#28BA62, #ebebeb, 30%);
}
td.weekend {
  //color: var(--ion-color-secondary-shade);
}
td.times {
  width: 400px;
  padding: 3px 0px 1px 2px;
}

span.oh-times {
  background: #88ccff
}

p.closed, div.closed {
  // color: var(--ion-color-dark-shade);
}

p.open, div.open {
  // color: var(--ion-color-success-shade);
}

p.unknown {
  color: #94d7b3
};

p.text-indent {
  text-indent: 1rem;
}

i.red-dot {
  font-style: normal;
  color: var(--ion-color-secondary);
}

a {
  color: var(--ion-color-tertiary-shade);
  text-decoration: none;
}

.float-right {
  float: right;
}

.ion-color-primary-contrast {
  color: var(--ion-color-primary-contrast);
}

ion-icon[item-left], ion-icon[item-start] {
  left: 10px;
  right: auto;
}

ion-icon.inline {
  position: relative;
  right: initial;
  top: initial;
  line-height: normal;

  &.padding-left {
    padding-left: 4px;
  }

  &:before {
    font-size: 1.4rem;
  }
}

ion-header {
  &.no-shadow {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}

ion-slides.hide-pager .swiper-pagination {
  display: none;
}

.add-to-route-popover {
  --offset-y: -100px;
}

.dnt-toast {
  top: calc(150px + var(--ion-safe-area-top, 0px));

  &.slim-header {
    top: calc(80px + var(--ion-safe-area-top, 0px));
  }
}

.embed-popover::part(content) {
  min-width: 820px !important;
  min-height: 640px !important;
  width: fit-content;
  height: fit-content;
}

.embed-iframe {
  position: relative;
}

.embed-ion-item {
  border-bottom: 1px solid #dedede !important;
  box-shadow: none !important;
}

.embed-label {
  margin: 13px 8px 13px 0;
}

.embed-ion-textarea {
  border: 1px solid rgba(0, 0, 0, .22);
  margin: 9px 8px !important;
  font-family: monospace;
  max-width: 450px;
  resize: vertical;
}

.embed-popover-viewport {
  overflow-y: hidden;
}

.embed-inner {
  display: table;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0.22);
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.link-popover {
  --width: 500px;
}

.text-color-grey {
  color: var(--ion-color-primary);
}

.text-color-white {
  color: #fff;
}

.no-color {
  .item-inner {
    border-bottom-color: transparent !important;
    box-shadow: none !important;
  }
}

.swiper-button-prev {
  color: white !important;
}

.swiper-button-next {
  color: white !important;
}

.alert-button.danger > .button-inner {
  color: var(--ion-color-danger);
}

.alert-message {
  overflow-y: auto;
}

.app-logo {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;

  --padding-start: 0;
  --padding-end: 0;

  &:hover {
    opacity: .8;
  }
}

.banner {
  position: absolute;
  right: 30px;
  top: 0;
  height: 50px;
  width: 40px;
  background-color: var(--ion-color-secondary);
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  text-align: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);

  > ion-icon {
    color: #fff;
    font-size: 25px;
    margin-top: 14px;
  }
}

.bar-button.close-button {
  margin: 0 6px;
}

.bg-attribution {
  display: none;
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 14px;
  color: #ebebeb;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);

  a {
    color: #bbbbbb;
  }
}

.expandable {
  height: 40%;
  overflow-y: auto;
  transition: height 0.5s ease;

  > ion-item ion-label {
    @extend .flex-row;

    > ion-icon {
      top: initial;
    }

    div {
      padding: 0;
    }
  }

  .item ion-icon[item-start] {
    margin: 15px 0;

    + .item-inner {
      margin-left: 36px;
    }
  }
}

.slim-list {
  margin: 0;
  padding: 0;

  ion-item {
    padding-right: 16px;
    text-align: left;

    &.disabled {
      opacity: .6;
      cursor: default;

      .icon {
        cursor: pointer;
        margin-right: 0;
      }
    }

    &.focused {
      background-color: #ebebeb;
    }

    &:last-child {
      --border-color: transparent;
    }
  }
}

@media (max-width: 699px) {
  .tour-details-toolbar {
    color: var(--ion-color-primary-shade);
    height: fit-content;
    height: 72 !important;
    // padding: 8px;
    display: unset;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 700px) {
  .tour-details-toolbar {
    color: var(--ion-color-primary-shade);
    height: fit-content;
    height: 36px !important;
    // padding: 8px;
    display: unset;
    align-items: center;
    justify-content: center;
  }
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  pointer-events: all !important;
}

.waypoint-input-search-results {
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  padding: 5px 0 0;
  color: var(--ion-color-primary);
  box-shadow: 0 3px 12px 2px rgba(0, 0, 0, .3);
  border-radius: 2px;
  overflow-y: auto;
  z-index: 10;

  .waypoint-input-search-result {
    padding: 0 16px;
    min-height: 46px;
    background-color: #fff;

    .search-result-inner {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      min-height: 46px;
      // height: 100%;
      width: 100%;
      padding: 5px 0;
      font-size: 14px;
      border-bottom: 1px solid #dedede;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &:last-child {
      .search-result-inner {
        border-bottom: none;
      }
    }

    &:active, &:focus {
      --color: var(--ion-color-light);
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.flippable {
  -webkit-transition: .3s ease-in-out;
  -moz-transition: .3s ease-in-out;
  transition: .3s ease-in-out;

  &.flipped {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.italic {
  font-style: italic;
}

.menu-button {
  font-size: 2rem;
  font-weight: 500;
  opacity: 0.8;
  padding: 0 12px;
  text-transform: none;

  &:hover, &.selected {
    opacity: 1;
  }
}

.menu-category {
  @extend .menu-button;
  padding: 0 48px;
}

.no-bottom-border {
  --border-style: none;
}

.no-margin {
  margin: 0;
}

.scroll-content {
  overflow-y: auto;
}

.sub-title {
  margin: 2px 0;
  padding: 0;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: normal;
  color: #585858;
}

.swiper-pagination-bullet-active {
  background: var(--ion-color-secondary);
}

.toast-message {
  white-space: pre-line;
}

.toolbar-md-primary .bar-button-clear-md:hover:not(.disable-hover),
.toolbar-md-primary .bar-button-default-md:hover:not(.disable-hover),
.toolbar-md-primary .bar-button-outline-md:hover:not(.disable-hover) {
  color: #fff;
}

.underline {
  border-bottom: 3px solid;
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-315 {
  transform: rotate(315deg);
}

.label-warning {
  color: var(--ion-color-danger) !important;
  font-size: 14px !important;
  white-space: break-spaces !important;
}

.routing-instructions-alert {
  .alert-checkbox-label {
    white-space: normal;
  }
}

.rotating {
  animation: spin 1.5s ease-in-out infinite;
  animation-iteration-count: infinite;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.images-modal {
  --border-radius: 0;
  --background: black;
  --width: 100%;
  --height: 100%;

  ::part(content) {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
}

.ios .images-modal {
  ::part(content) {
      top: 26px;
  }
}

// device specific styles
@import "scss/landscape";
@import "scss/portrait";

// platform specific styles
@import "scss/ios";
@import "scss/md";

// ie11 and edge fixes
@import "scss/ie_edge";
